import {getRouteNameByGameType} from "../gameCommon";
import {onBeforeWindowUnload} from "../utils/beforeWindowUnload";

export default {
    methods: {
        alreadyJoinedCheck({isJoined, isExistsJoinedRoom, isGameOver, isObserver, gameSN, gameType, gameUri}){
            if( !isJoined ){
                console.log('not exists joined room');
                return
            }

            if( !isExistsJoinedRoom ){
                console.log(`not exists joined room ${gameSN}`);
                return
            }
            
            if( isGameOver ){
                console.log(`joined room game over ${gameSN}`);
                return
            }

            console.log(`exists joined room ${gameSN}`);

            if( isJoined && !isObserver ){
                const routeName = getRouteNameByGameType(gameType)
                console.log('exists playing room');
                window.removeEventListener('beforeunload', onBeforeWindowUnload);
                window.location.href = `${gameUri}/${routeName}?roomCode=${gameSN}&token=${this.token}`;
            }else{
                console.log('not exists playing room');
            }
        },
    }
};
